.contact_container {
    display: grid;
    justify-items: center;
    padding-inline: 5rem;
    height: 85vh;
    padding-top: 15vh;
  }
  
  .contact_form_container {
    display: grid;
    width: 20rem;
    grid-template-areas: 
    'name'
    'email'
    'phone'
    'message'
    'button'
    ;
    gap: .4rem;
    padding-bottom: 2rem;
  }
  
  .name {
    grid-area: name;
  }
  
  .email {
    grid-area: email;
  }
  
  .phone {
    grid-area: phone;
  }
  
  .message {
    grid-area: message;
  }
  
  .contact_button {
    grid-area: button;
  }
  
  @media screen and (min-width: 768px) {
    .contact_text {
      width: 50vw;
    }
  
    .contact_form_container {
        width: 70vw;
        gap: 2vw;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: 
        'name email phone'
        'message message message'
        '....... button ........';
    }
  }
  