.container_main {
    width: 70vw;
}

.container_home {
    position: relative; 
    display: grid;
    height: 85vh;
    padding-top: 15vh;
    padding-bottom: 5rem;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    gap: 5rem;
}

.image_1, .image_2 {
    position: relative;
    background-image: url('/src/images/squares.svg');
    background-size: cover;
    width: 20rem;
    height: 20rem;
    
}

.container_title {
    position: relative;
    
}

.title_home1 {
    color: var(--color-accent);
}

.container_traits {
    position: relative;
    display: grid;
    gap: 5rem;
    text-align: center;
    justify-items: center;
    
}

.trait_name {
    color: var(--color-accent);
}

.trait_container {
    position: relative;
    padding-inline: 2rem;
    background-image: linear-gradient(var(--color-accent) -100%, var(--color-black) 70%);
    border-top: solid 1px rgba(255, 116, 2, 0.726);
}

@media screen and (min-width: 768px) {
    .container_main {
        width: 90vw;
    }

    .container_title {
        width: auto;
        justify-items: flex-start;
    }

    .image_1 {
        width: 35vw;
        height: 35vw;
    }

    /*the images need to be changed  */
    .image_2 {
        height: 30rem;
        width: 30rem;
    }

    .container_traits {
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        gap: 5rem;
    }

    
}

@media screen and (min-width: 1180px) {

    .image_1 {
        width: 30vw;
        height: 30vw;
        left: -10vw;
    }
    .container_home {
        display: flex;
    }

    .container_traits {
        display: flex;
    }

}