:root {
  --color-black: black;
  --color-accent: rgb(221, 42, 27);
  --color-white: white;

  --font-smallest: clamp(.8rem, 1.2vw, 1rem);
  --font-small: clamp(1rem, 1.5vw, 1.25rem);
  --font-medium: clamp(1.2rem, 2vw, 1.7rem);
  --font-big: clamp(3rem, 4vw, 4.5rem);
}

body, html {
  margin: 0;
  font-family: 'Agdasima', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-white);
  background-color: var(--color-black);
  box-sizing: border-box;
  display: grid;
  justify-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: var(--font-big);
  letter-spacing: 0.1rem;
  line-height: 0.9rem;
  white-space: nowrap;
}

h2 {
  font-size: var(--font-medium);
  color: var(--color-accent);
  letter-spacing: 0.2rem;
}

h3 {
  font-size: var(--font-small);
  color: var(--color-accent);
}

h4 {
  font-size: var(--font-medium);
  line-height: 1rem;
  
}

p {
  font-size: var(--font-smallest);
  line-height: 1.3rem;
  letter-spacing: 0.15rem;
}

a {
  text-decoration: none;
  color: var(--color-white);
  font-style:normal;
  font-size: var(--font-small);

}