.navbar {
    position: absolute;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    top:0;
    margin-top: 2rem;
    height: 3rem;
    z-index: 50;
}

.logo {
    background-image: url('../images/Untitled.png');
    background-size: cover;
    width: 5rem;
    height: 5rem;
}

.container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    margin-right: 3rem;
}

.lowered {
    position: fixed;
    background-color: var(--color-black);
    left: 0;
    right: 0;
    top: 0;
    padding: 3rem;
    z-index: 10;
    margin-right: 0;
    justify-content: center;
}


@media screen and (min-width: 768px) {
    .logo {
        width: 10rem;
        height: 10rem;
    }
}
