.about_me {
    position: relative;
    display: grid;
    justify-items: center;
    height: 100vh;
    gap: 5rem;
    padding-block: 10rem;
}

.text_2_mini_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-block: 2rem;
    align-items: center;
}

.text_2_subcontainer {
    width: 15rem;
}

.image_2 {
    width: 55vw;
    height: 55vw;
}

.container_text_2 {
    display: grid;
    justify-items: center;
    justify-items: flex-start;
    width: 20rem;
}

.text_2_icon_1 {
    background-image: url('/src/images/lightbulb-dollar.png');
    filter: invert(50%) sepia(100%) hue-rotate(350deg);
    
}

.text_2_icon_2 {
    background-image: url('/src/images/database.png');
    filter: invert(50%) sepia(100%) hue-rotate(350deg);
}

.text_2_icon_1, .text_2_icon_2 {
    background-size: cover;
    width: 4rem;
    height: 4rem;
} 

.link_services {
    color: var(--color-accent);
    font-size: var(--font-medium);
    border: solid 2px  var(--color-accent);
    width: 100%;
    justify-self: center;
    text-align: center;
    padding-block: 1rem;
}

@media screen and (min-width: 768px) {
    .image_2 {
        width: 25rem;
        height: 25rem;
    }
    .container_text_2 {
        width: 30rem;
    }

    .text_2_subcontainer {
        justify-self: flex-start;
        width: 22rem;
    }
}

@media screen and (min-width: 1180px) {
    .about_me {
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
}